import axios from 'axios';
import authHeader from './auth-header';
import {API_URL} from "@/config";

const VIDEO_API = API_URL+'/Video/';

class VideoService {


    getMyVideos() {
        return axios.get(VIDEO_API, { headers: authHeader() });
    }


    deleteVideo(id) {
        return axios.delete(VIDEO_API+id, { headers: authHeader() });
    }
}

export default new VideoService();