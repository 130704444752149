import axios from 'axios';
import {API_URL} from "@/config";
import authHeader from "@/services/auth-header";

const AUTH_API = API_URL + '/Account/';

class AuthService {
    login(user) {
        return axios
            .post(AUTH_API + 'Login', {
                email: user.username, password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    update(model) {
        return axios
            .post(AUTH_API + 'Update', model,{ headers: authHeader() })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    refreshMyUser() {
        return axios
            .post(AUTH_API + 'Refresh')
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    changePassword(model) {
        return axios.post(AUTH_API + "ChangePassword", model, {headers: authHeader()});
    }

    logout() {
        localStorage.removeItem('user');

    }

    register(user) {
        return axios.post(AUTH_API + 'Register', {
            email: user.email, password: user.password
        });
    }
}

export default new AuthService();
