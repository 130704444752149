import { createWebHistory, createRouter } from "vue-router";
// import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
//import Register from "./components/Register.vue";

// lazy-loaded
// const Profile = () => import("./components/Profile.vue")
// const Video = () => import("./components/Video.vue")
// const PlayExample = () => import("./components/PlayExample.vue")
// const Creative = () => import("./components/Creative.vue")
// const Campaign = () => import("./components/Campaign.vue")
// const Platform = () => import("./components/Platform.vue")
// const PlatformVideo = () => import("./components/PlatformVideo.vue")
const Demo = () => import("./components/Demo.vue")
const Dollar = () => import("./components/Dollar.vue")

const routes = [
    {
        path: "/",
        name: "home",
        component: Login,
    },
    // {
    //     path: "/home",
    //     component: Home,
    // },
    {
        path: "/login",
        component: Login,

    },
    // {
    //     path: "/register",
    //     component: Login,
    // },
    // {
    //     path: "/profile",
    //     name: "profile",
    //     // lazy-loaded
    //     component: Profile,
    // },
    // {
    //     path: "/video",
    //     name: "video",
    //     // lazy-loaded
    //     component: Video,
    //     meta: { title: 'Videus.Ad - Мои видео' }
    // },
    {
        path: "/test",
        name: "test",
        // lazy-loaded
        component: Dollar,
        meta: { token: true }
    },
    // {
    //     path: "/example/:id",
    //     name: "example",
    //     // lazy-loaded
    //     component: PlayExample,
    //     meta: { title: 'Videus.Ad - Тестирование видео' }
    // },
    // {
    //     path: "/creatives/:campaignId",
    //     name: "creatives",
    //     // lazy-loaded
    //     component: Creative,
    //     meta: { title: 'Videus.Ad - Мои объявления' }
    // },
    // {
    //     path: "/platform-videos/:platformId",
    //     name: "platform-videos",
    //     // lazy-loaded
    //     component: PlatformVideo,
    //     meta: { title: 'Videus.Ad - Мои видео' }
    // },
    // {
    //     path: "/campaign/",
    //     name: "campaign",
    //     // lazy-loaded
    //     component: Campaign,
    //     meta: { title: 'Videus.Ad - Мои кампании' }
    // },

    // {
    //     path: "/platform/",
    //     name: "platform",
    //     // lazy-loaded
    //     component: Platform,
    //     meta: { title: 'Videus.Ad - Мои платформы' }
    // },
    {
        path: "/demo/",
        name: "demo",
        // lazy-loaded
        component: Demo,
        meta: { title: 'Videus.Ad - Демонстрация' }
    },
];

// eslint-disable-next-line no-unused-vars
export const router = createRouter({
    history: createWebHistory(),
    routes,
});
