import AdService from "@/services/ad.service";

const initialState = {
    status: {processing: false, isError: false, errorText: "", isSuccess: false, successText: ""},
    campaigns: [],
    creatives: [],
    platforms: [],
    platformVideos: [],
    currentPlatform: {},
    currentCampaign: {},
    wordsInVideo: [],
    contentWordsInVideo: []
};


export const ad = {
    namespaced: true, state: initialState, actions: {
        getMyCreatives({commit}, id) {
            commit('setProcessing', true);
            return AdService.getMyCreatives(id).then(answer => {
                commit('getMyCreativesSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getMyCreativesFailure');
                return Promise.reject(error);
            });
        }, getPlatformVideos({commit}, id) {
            commit('setProcessing', true);
            return AdService.getPlatformVideos(id).then(answer => {
                commit('getPlatformVideosSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getPlatformVideosFailure');
                return Promise.reject(error);
            });
        }, getCampaign({commit}, id) {
            commit('setProcessing', true);
            return AdService.getCampaign(id).then(answer => {
                commit('getCampaignSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getCampaignFailure');
                return Promise.reject(error);
            });
        }, getPlatform({commit}, id) {
            commit('setProcessing', true);
            return AdService.getPlatform(id).then(answer => {
                commit('getPlatformSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getPlatformFailure');
                return Promise.reject(error);
            });
        },postCreative({commit}, model) {
            commit('setProcessing', true);
            return AdService.postCreative(model).then(answer => {
                commit('postCreativeSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('postCreativeFailure');
                return Promise.reject(error);
            });
        }, patchCreative({commit}, model) {
            commit('setProcessing', true);
            return AdService.patchCreative(model).then(answer => {
                commit('patchCreativeSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('postCreativeFailure');
                return Promise.reject(error);
            });
        }, deleteCreative({commit}, id) {
            commit('setProcessing', true);
            return AdService.deleteCreative(id).then(answer => {
                commit('deleteCreativeSuccess', id);
                return Promise.resolve(answer.data);
            }, error => {
                commit('deleteCreativeFailure', id);
                return Promise.reject(error);
            });
        },

        getMyCampaigns({commit}) {
            commit('setProcessing', true);
            return AdService.getMyCampaigns().then(answer => {
                commit('getMyCampaignsSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getMyCampaignsFailure');
                return Promise.reject(error);
            });
        },

        postCampaign({commit}, model) {
            commit('setProcessing', true);
            return AdService.postCampaign(model).then(answer => {
                commit('postCampaignSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('postCampaignFailure');
                return Promise.reject(error);
            });
        }, patchCampaign({commit}, model) {
            commit('setProcessing', true);
            return AdService.patchCampaign(model).then(answer => {
                commit('patchCampaignSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('postCampaignFailure');
                return Promise.reject(error);
            });
        }, deleteCampaign({commit}, id) {
            commit('setProcessing', true);
            return AdService.deleteCampaign(id).then(answer => {
                commit('deleteCampaignSuccess', id);
                return Promise.resolve(answer.data);
            }, error => {
                commit('deleteCampaignFailure', id);
                return Promise.reject(error);
            });
        },

        getMyPlatforms({commit}) {
            commit('setProcessing', true);
            return AdService.getMyPlatforms().then(answer => {
                commit('getMyPlatformsSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getMyPlatformsFailure');
                return Promise.reject(error);
            });
        }, getPlatforms({commit}) {
            commit('setProcessing', true);
            return AdService.getPlatforms().then(answer => {
                commit('getPlatformsSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getMyPlatformsFailure');
                return Promise.reject(error);
            });
        }, postPlatform({commit}, model) {
            commit('setProcessing', true);
            return AdService.postPlatform(model).then(answer => {
                commit('postPlatformSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('postPlatformFailure');
                return Promise.reject(error);
            });
        }, validatePlatform({commit}, id) {
            commit('setProcessing', true);
            return AdService.validatePlatform(id).then(answer => {
                commit('validationPlatformSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('validationPlatformFailure');
                return Promise.reject(error);
            });
        }, patchPlatform({commit}, model) {
            commit('setProcessing', true);
            return AdService.patchPlatform(model).then(answer => {
                commit('patchPlatformSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('postPlatformFailure');
                return Promise.reject(error);
            });
        }, deletePlatform({commit}, id) {
            commit('setProcessing', true);
            return AdService.deletePlatform(id).then(answer => {
                commit('deletePlatformSuccess', id);
                return Promise.resolve(answer.data);
            }, error => {
                commit('deletePlatformFailure', id);
                return Promise.reject(error);
            });
        },

        getContentWords({commit}, id) {
            commit('setProcessing', true);
            return AdService.getContentWords(id).then(answer => {
                commit('getContentWordsSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getContentWordsFailure');
                return Promise.reject(error);
            });
        }, getVideoWords({commit}, id) {
            commit('setProcessing', true);
            return AdService.getVideoWords(id).then(answer => {
                commit('getVideoWordsSuccess', answer.data);
                return Promise.resolve(answer.data);
            }, error => {
                commit('getVideoWordsFailure');
                return Promise.reject(error);
            });
        },

    }, mutations: {
        setProcessing(state, status) {
            state.status.processing = status;
        }, getMyCreativesSuccess(state, creatives) {
            state.status.processing = false;
            state.creatives = creatives;
        }, getPlatformVideosSuccess(state, videos) {
            state.status.processing = false;
            state.platformVideos = videos;
        }, getCampaignSuccess(state, campaign) {
            state.status.processing = false;
            state.currentCampaign = campaign;
        }, getPlatformSuccess(state, platform) {
            state.status.processing = false;
            state.currentPlatform = platform;
        },getMyPlatformsSuccess(state, platforms) {
            state.status.processing = false;
            state.platforms = platforms;
        }, getPlatformsSuccess(state, platforms) {
            state.status.processing = false;
            state.platforms = platforms;
        }, getMyPlatformsFailure(state) {
            state.status.processing = false;
            state.platforms = [];
        }, getPlatformVideosFailure(state) {
            state.status.processing = false;
            state.platformVideos = [];
        }, getCampaignFailure(state) {
            state.status.processing = false;
            state.status.isError = true;
            setTimeout(() => {
                state.status.isError = false;
            }, 2000);
            state.status.errorText = 'Ошибка получения кампании';
            state.currentCampaign = {};
        }, getPlatformFailure(state) {
            state.status.processing = false;
            state.status.isError = true;
            setTimeout(() => {
                state.status.isError = false;
            }, 2000);
            state.status.errorText = 'Ошибка получения платформы';
            state.currentPlatform = {};
        }, validationPlatformFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка валидации платформы';
            state.currentCampaign = {};
        }, validationPlatformSuccess(state, platform) {
            state.status.processing = false;
            state.status.isSuccess = true;
            setTimeout(() => {
                state.status.isSuccess = false;
            }, 2000);
            state.platform = state.platforms.filter(function (obj) {
                return obj.isValidated !== platform.isValidated;
            });
            state.status.successText = 'Успешная валидации платформы';
            state.currentCampaign = {};
        }, getMyCreativesFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка получения данных';
            state.creatives = [];
        }, postCreativeSuccess(state, creative) {
            state.status.processing = false;
            state.creatives.push(creative);
        }, postPlatformSuccess(state, platform) {
            state.status.processing = false;
            state.platforms.push(platform);
        }, patchCreativeSuccess(state, creative) {
            state.status.processing = false;
            state.creatives = state.creatives.filter(function (obj) {
                return obj.id !== creative.id;
            });
            state.creatives.push(creative);
        }, patchPlatformSuccess(state, platform) {
            state.status.processing = false;
            state.platform = state.platforms.filter(function (obj) {
                return obj.id !== platform.id;
            });
            state.creatives.push(platform);
        }, postCreativeFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка сохранения данных';
        }, postPlatformFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка сохранения данных';
        }, deleteCreativeSuccess(state, id) {
            state.status.processing = false;
            state.creatives = state.creatives.filter(function (obj) {
                return obj.id !== id;
            });
        }, deletePlatformSuccess(state, id) {
            state.status.processing = false;
            state.platforms = state.platforms.filter(function (obj) {
                return obj.id !== id;
            });
        }, deleteCreativeFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка удаления данных';
        }, deletePlatformFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка удаления данных';
        }, getMyCampaignsSuccess(state, campaigns) {
            state.status.processing = false;
            state.campaigns = campaigns;
        }, getMyCampaignsFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка получения данных';
            state.campaigns = [];
        }, postCampaignSuccess(state, campaign) {
            state.status.processing = false;
            state.campaigns.push(campaign);
        }, patchCampaignSuccess(state, campaign) {
            state.status.processing = false;
            state.campaigns = state.campaigns.filter(function (obj) {
                return obj.id !== campaign.id;
            });
            state.campaigns.push(campaign);
        }, postCampaignFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка сохранения данных';
        }, deleteCampaignSuccess(state, id) {
            state.status.processing = false;
            state.campaigns = state.campaigns.filter(function (obj) {
                return obj.id !== id;
            });
        }, deleteCampaignFailure(state) {
            state.status.processing = false;
            state.status.error = true;
            setTimeout(() => {
                state.status.error = false;
            }, 2000);
            state.status.errorText = 'Ошибка удаления данных';
        }, getVideoWordsSuccess(state, words) {
            state.status.processing = false;
            state.wordsInVideo = words;
        }, getVideoWordsFailure(state) {
            state.status.processing = false;
            state.wordsInVideo = {};
        }, getContentWordsSuccess(state, words) {
            state.status.processing = false;
            state.contentWordsInVideo = words;
        }, getContentWordsFailure(state) {
            state.status.processing = false;
            state.contentWordsInVideo = {};
        },
    }
};