import axios from 'axios';
import authHeader from './auth-header';
import {API_URL} from "@/config";

const AD_API = API_URL+'/Ad/';

class AdService {

    getCampaign(id) {
        return axios.get(AD_API+"Campaign/"+id, { headers: authHeader() });
    }

    getPlatform(id) {
        return axios.get(AD_API+"Platform/"+id, { headers: authHeader() });
    }

    getMyCreatives(id) {
        return axios.get(AD_API+"Creatives/ByCampaign/"+id, { headers: authHeader() });
    }

    getPlatformVideos(id) {
        return axios.get(AD_API+"Platform/Videos/"+id, { headers: authHeader() });
    }

    validatePlatform(id) {
        return axios.post(AD_API+"Platform/Validate/",{id:id}, { headers: authHeader() });
    }

    getMyPlatforms() {
        return axios.get(AD_API+"Platform/My", { headers: authHeader() });
    }

    getPlatforms() {
        return axios.get(AD_API+"Platform", { headers: authHeader() });
    }

    postPlatform(model) {
        return axios.post(AD_API+"Platform",model, { headers: authHeader() });
    }

    patchPlatform(model) {
        return axios.patch(AD_API+"Platform",model, { headers: authHeader() });
    }

    postCreative(model) {
        return axios.post(AD_API+"Creative",model, { headers: authHeader() });
    }

    patchCreative(model) {
        return axios.patch(AD_API+"Creative",model, { headers: authHeader() });
    }

    deleteCreative(id) {
        return axios.delete(AD_API+"Creative/"+id, { headers: authHeader() });
    }

    deletePlatform(id) {
        return axios.delete(AD_API+"Platform/"+id, { headers: authHeader() });
    }

    getMyCampaigns() {
        return axios.get(AD_API+"Campaign/", { headers: authHeader() });
    }

    postCampaign(model) {
        return axios.post(AD_API+"Campaign",model, { headers: authHeader() });
    }

    patchCampaign(model) {
        return axios.patch(AD_API+"Campaign",model, { headers: authHeader() });
    }

    deleteCampaign(id) {
        return axios.delete(AD_API+"Campaign/"+id, { headers: authHeader() });
    }

    getVideoWords(id) {
        return axios.get(AD_API+"ByVideo/Words/"+id, { headers: authHeader() });
    }

    getContentWords(id) {
        return axios.get(AD_API+"ByVideo/"+id, { headers: authHeader() });
    }


}

export default new AdService();