import { createStore } from "vuex";
import { auth } from "./auth.module";
import {video} from "@/store/video.module";
import {ad} from "@/store/ad.module";

const store = createStore({
    modules: {
        auth,
        video,
        ad
    },
});

export default store;