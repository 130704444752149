
import VideoService from "@/services/video.service";

const initialState = { status: { processing: false, isError: false, errorText:"", isSuccess: false, successText:"" }, videos: [] };


export const video = {
    namespaced: true,
    state: initialState,
    actions: {
        getMyVideos({ commit }) {
            commit('setProcessing', true);
            return VideoService.getMyVideos().then(
                answer => {
                    commit('getMyVideosSuccess', answer.data);
                    return Promise.resolve(answer.data);
                },
                error => {
                    commit('getMyVideosFailure');
                    return Promise.reject(error);
                }
            );
        },
        deleteVideo({ commit }, id) {
            commit('setProcessing', true);
            return VideoService.deleteVideo(id).then(
                answer => {
                    commit('deleteSuccess', id);
                    return Promise.resolve(answer.data);
                },
                error => {
                    commit('setProcessing', false);
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        setProcessing(state, status) {
            state.status.processing = status;
        },
        getMyVideosSuccess(state, videos) {
            state.status.processing = false;
            state.videos = videos;
        },
        getMyVideosFailure(state) {
            state.status.processing = false;
            state.videos = {};
        },
        deleteSuccess(state, id) {
            state.status.processing = false;
            state.videos =  state.videos .filter(function( obj ) {
                return obj.id !== id;
            });
        },
    }
};