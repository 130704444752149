import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true, isError: false, errorText:"", isSuccess: false, successText:"" }, user }
    : { status: { loggedIn: false, isError: false, errorText:"", isSuccess: false, successText:"" }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        update({ commit }, model) {
            return AuthService.update(model).then(
                user => {
                    commit('updateSuccess', model);
                    return Promise.resolve(user);
                },
                error => {
                    commit('updateFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshMyUser({ commit }) {
            return AuthService.refreshMyUser().then(
                user => {
                    commit('refreshSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('refreshFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        changePassword({ commit }, model) {
            return AuthService.changePassword(model).then(
                response => {
                    commit('changeSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('changeFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        changeSuccess(state) {
            state.status.isSuccess = true;
            state.status.successText = "Пароль успешно изменен";
            setInterval(()=>{
                state.status.isSuccess = false;
            }, 2000)
        },
        changeFailure(state) {
            state.status.isError = true;
            state.status.errorText = "Ошибка смены пароля";
            setInterval(()=>{
                state.status.isError = false;
            }, 2000)
        },
        updateSuccess(state, user) {
            state.status.isSuccess = true;
            state.user = user;
            state.status.successText = "Информация изменена";
            setInterval(()=>{
                state.status.isSuccess = false;
            }, 2000)
        },
        updateFailure(state) {
            state.status.isError = true;
            state.status.errorText = "Ошибка изменения данных профиля";
            setInterval(()=>{
                state.status.isError = false;
            }, 2000)
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
       refreshSuccess(state, user) {
           state.user = user;
        },
        refreshFailure(state) {
            state.status.loggedIn = false;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};