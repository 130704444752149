import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
    faHome,
    faUser,
    faVideo,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faPlay,
    faTrash,
    faFileWord,
    faAd,
    faPencil
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faVideo, faPlay, faTrash, faFileWord, faAd, faPencil);

export {FontAwesomeIcon};