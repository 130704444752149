export const API_URL = getApiUrl();


function getApiUrl(){
    //return 'https://api.ad.videus.ru';

    if (window.location.hostname.includes('videus'))
        return 'https://api.ad.videus.ru';
    else
        return 'http://localhost:8080';
}