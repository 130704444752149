<template>
  <div id="app">
    <!-- <MDBNavbar expand="lg" light bg="white" container position="sticky" v-if="loginned && showHeader">
      <MDBBtn color="primary" class="me-2 btn-floating btn-sm" @click="showBackMenu=!showBackMenu">  <MDBIcon iconStyle="fas" icon="bars" class="fa-fw me-3" /> </MDBBtn>
    </MDBNavbar> -->
    <MDBSideNav
      v-if="loginned && showHeader"
      v-model="showBackMenu"
      :backdrop="true"
      id="sidenavSlim"
    >
      <MDBSideNavMenu>
        <MDBSideNavItem>
          <MDBSideNavLink to="/" @click="showBackMenu = false">
            <MDBIcon iconStyle="far" icon="smile" class="fa-fw me-3" />
            <span class="sidenav-non-slim">Главная</span>
          </MDBSideNavLink>
        </MDBSideNavItem>
        <MDBSideNavItem collapse icon="grin" title="Видео">
          <MDBSideNavItem>
            <MDBSideNavLink to="/video" @click="showBackMenu = false"
              >Мои видео</MDBSideNavLink
            >
          </MDBSideNavItem>
        </MDBSideNavItem>
        <MDBSideNavItem collapse icon="grin-wink" title="Реклама">
          <MDBSideNavItem v-if="currentUser.isPlatformUser">
            <MDBSideNavLink to="/platform" @click="showBackMenu = false"
              >Площадки</MDBSideNavLink
            >
          </MDBSideNavItem>
          <MDBSideNavItem v-if="currentUser.isAdUser">
            <MDBSideNavLink to="/campaign" @click="showBackMenu = false"
              >Мои кампании</MDBSideNavLink
            >
          </MDBSideNavItem>
        </MDBSideNavItem>
        <MDBSideNavItem collapse icon="grin-wink" title="Мой пользователь">
          <MDBSideNavItem>
            <MDBSideNavLink to="/profile" @click="showBackMenu = false"
              >Профиль</MDBSideNavLink
            >
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink @click="logOut">Выход</MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavItem>
      </MDBSideNavMenu>
    </MDBSideNav>

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
const DEFAULT_TITLE = "Videus - AI subtitle generator";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBIcon,
} from "mdb-vue-ui-kit";
export default {
  components: {
    // MDBNavbar,
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBIcon,
    // MDBBtn,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loginned() {
      return (
        this.$store.state.auth.user !== null &&
        this.$store.state.auth.user !== undefined
      );
    },
    showHeader() {
      return this.$route.name !== "demo";
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  data() {
    return {
      showBackMenu: false,
      dropdownUserMenu: false,
      collapseHeader: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || DEFAULT_TITLE;
      },
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100vw !important;
}
</style>
