import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import {router} from "@/router";
import Antd from 'ant-design-vue';


import 'mdb-vue-ui-kit/css/mdb.min.css';

import 'video.js/dist/video-js.css'


createApp(App)
    .use(router)
    .use(store)
    .use(Antd)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'ant-design-vue/dist/antd.css';